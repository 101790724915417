import { LinkButton } from "@basaltbytes/ui/link-button";
import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
  type ErrorResponse,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { ArrowLeft, RefreshCwIcon } from "lucide-react";

import { getErrorMessage } from "~/utils/misc";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => (
    <p>
      {error.status} {error.data}
    </p>
  ),
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  const params = useParams();

  if (typeof document !== "undefined") {
    console.error(error);
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="container flex max-w-4xl flex-col gap-8 rounded-md border border-input bg-card p-20">
        <div className="flex flex-row items-center justify-start gap-10">
          <svg
            width="64px"
            height="64px"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-2 h-16 fill-primary"
          >
            <path
              // className="fill-green-400"
              d="M105 32v30h191.607l16.663-30zm3.805 48c4.309 10.899-5.352 29.724-9.276 38.588-11.006 24.703-28.728 57.602-42.02 95.43-13.29 37.828-22.212 80.865-15.15 126.119 7.063 45.254 30.318 92.356 79.56 137.496L124.5 480h263l2.58-2.367c49.243-45.14 72.498-92.242 79.56-137.496 7.063-45.254-1.859-88.291-15.15-126.12-13.29-37.827-31.013-70.726-42.02-95.43-4.475-10.046-7.742-18.751-9.312-25.372l-75.117 35.273 63.037 32.295-36.267 78.457 61.921 19.205-153.521 138.17 65.975-116.119-58.2-3.172 47.735-96.314-73.21-27.018L286.61 80H108.805z"
            />
          </svg>
          <h1 className="text-6xl font-semibold tracking-tight">Error</h1>
        </div>

        <p className="text-muted-foreground">
          An unexpected error occured during the operation.
        </p>
        <div className="flex flex-row justify-start gap-4">
          <LinkButton to=".">
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to previous page
          </LinkButton>
          <LinkButton to="." relative="path">
            <RefreshCwIcon className="mr-2 h-4 w-4" /> Retry
          </LinkButton>
        </div>
        {isRouteErrorResponse(error) ? (
          (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        ) : ENV && ENV.MODE === "development" ? (
          <pre className="max-w-4xl overflow-x-auto rounded-md bg-muted p-4 text-xs">
            <code className="overflow-auto">
              {unexpectedErrorHandler(error)}
            </code>
          </pre>
        ) : null}
      </div>
    </div>
  );
}
